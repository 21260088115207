// import { defineNuxtPlugin } from "#app";

// import hljs from "highlight.js/lib/core";
// import bash from "highlight.js/lib/languages/bash";
// import javascript from "highlight.js/lib/languages/javascript";
// import docker from "highlight.js/lib/languages/dockerfile";
// import typescript from "highlight.js/lib/languages/typescript";

import mdit from "markdown-it";
import attrs from "markdown-it-attrs";
// import anchor from "markdown-it-anchor";
// import toc from "markdown-it-toc-done-right";
import type MarkdownIt from "markdown-it";

// import sub from 'markdown-it-sub';
// import sup from 'markdown-it-sup';
// import fn from 'markdown-it-footnote';
// import emo from 'markdown-it-emoji';
// import def from 'markdown-it-deflist';
// import ins from 'markdown-it-ins';
// import container from 'markdown-it-container';

// hljs.registerLanguage("bash", bash);
// hljs.registerLanguage("javascript", javascript);
// hljs.registerLanguage("dockerfile", docker);
// hljs.registerLanguage("typescript", typescript);

const markdownit: MarkdownIt = new mdit({
  html: true,
  xhtmlOut: false,
  breaks: true,
  langPrefix: "language-",
  linkify: false,
  typographer: false,
  quotes: "“”‘’",
  //   highlight: function async(str, lang) {
  //     if (lang && hljs.getLanguage(lang)) {
  //       try {
  //         return (
  //           '<pre class="hljs"><code>' +
  //           hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
  //           "</code></pre>"
  //         );
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //     return (
  //       '<pre class="hljs"><code>' +
  //       markdownit.utils.escapeHtml(str) +
  //       "</code></pre>"
  //     );
  //   },
}).use(attrs, {
  allowedAttributes: ["target"],
});
//   .use(anchor, {
//     level: [2, 3],
//     permalink: anchor.permalink.headerLink({ safariReaderFix: true }),
//   })
//   .use(toc, { level: [2, 3], containerId: "markdown-toc" });
//   .use(sub)
//   .use(sup)
//   .use(fn)
//   .use(emo)
//   .use(def)
//   .use(ins)
//   .use(container,'codeblock',{marker:'@'});

// markdownit.linkify.set({ fuzzyEmail: false });

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide("mdit", markdownit);
});
