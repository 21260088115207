<template>
  <div>
    <NuxtLayout></NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { seoConstants } from "./seoConstants";

useHeadSafe({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - TechGen Solutions`
      : "TechGen Solutions";
  },
  htmlAttrs: {
    lang: "en",
  },
  link: [
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon-32x32.png",
    },
  ],
});

useSeoMeta({
  ogTitle: "TechGen Solutions",
  description: seoConstants.homepage.description,
});
</script>

<style>
html {
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  scroll-padding-top: 2.8rem;
  @apply md:text-[14px];
}

h1 {
  @apply font-sans text-blue-800 dark:text-blue-600 tracking-wider text-3xl md:text-4xl font-bold py-1 md:py-3;
}

h2 {
  @apply font-sans text-blue-800 dark:text-blue-600 tracking-wider text-2xl md:text-3xl font-bold py-1 md:py-2;
}

h3 {
  @apply font-sans text-blue-800 dark:text-blue-600 tracking-wider text-xl md:text-2xl font-bold py-1;
}

h4 {
  @apply font-sans text-blue-800 dark:text-blue-600 text-[1.5rem] font-bold;
}

a:active,
a:visited,
a {
  @apply transition-all duration-300 text-blue-600 dark:text-blue-400 underline underline-offset-4 decoration-1 decoration-orange-300 hover:decoration-orange-300 dark:decoration-yellow-600;
}

hr {
  @apply border-slate-200 dark:border-slate-500 border-t-2 my-4 w-[50vw] max-w-[50vw] mx-auto;
}

.techgen-yellow {
  color: rgb(252, 186, 0);
}

.md-content h1 {
  @apply mt-5 mb-4 p-0 font-sans tracking-wider font-bold dark:text-yellow-500 text-blue-700 text-3xl md:text-4xl;
}
.md-content h2 {
  @apply mb-4 p-0 pl-2 font-sans tracking-wider font-semibold dark:text-orange-500 text-blue-700 uppercase border-l-4 border-orange-300 dark:border-blue-400;
}
.md-content h3 {
  @apply mb-2 p-0 pl-2 font-sans font-semibold dark:text-orange-600 text-blue-900;
}
.md-content p {
  @apply pl-2 text-lg md:text-xl leading-relaxed;
}
.md-content ul {
  @apply mb-8;
}
.md-content ul li {
  @apply text-lg md:text-xl leading-relaxed;
}
.md-content th {
  @apply text-lg md:text-xl leading-relaxed;
}
.md-content td {
  @apply text-lg md:text-xl leading-relaxed;
}
.md-content img {
  @apply mt-2 mb-0;
}
.md-content ::marker {
  @apply text-orange-400;
}

.residential-tag {
  @apply bg-teal-200 !important;
}

.residential-tag-dark {
  @apply dark:bg-teal-800 !important;
}
</style>
