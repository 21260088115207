export interface Products {
  id: string;
  name: string;
  product_number: string;
  product_link: string;
  images: ImageElement[];
  tags: Tag[];
  date_updated: string;
}

export interface ImageElement {
  main_image: boolean;
  image: ImageImage;
}

export interface ImageImage {
  id: string;
  title: string;
}

export interface Tag {
  tag_id: TagID;
}

export interface TagID {
  id: string;
  name: string;
  slug: string;
  type: string;
  icon: null | string;
  brand_link: null | string;
}

export interface SingleProduct {
  id: string;
  name: string;
  description: string;
  product_number: string;
  product_link: string | null;
  images: ImageElement[];
  tags: SingleProductTag[];
}

export interface SingleProductTag {
  tag_id: SingleProductTagID;
}

export interface SingleProductTagID {
  id: string;
  name: string;
  slug: string;
  type: string;
  brand_link: null | string;
  icon: null | string;
}

// remove existing keys from TagID
export type SidebarCategory = TagID;

export interface ServerProductSearchQuery {
  search?: string;
  slug?: string;
}

export const PRODUCTS_PER_PAGE = 12;

export const productsFetchQuery = (pageNum: number) => {
  return {
    fields: [
      "id",
      "name",
      "product_number",
      "product_link",
      "images.image.id",
      "images.image.title",
      "images.main_image",
      "tags.tag_id.id",
      "tags.tag_id.name",
      "tags.tag_id.slug",
      "tags.tag_id.type",
      "tags.tag_id.icon",
      "tags.tag_id.brand_link",
      "date_updated",
    ],
    filter: {
      status: {
        _eq: "published",
      },
    },
    limit: PRODUCTS_PER_PAGE,
    page: pageNum,
    sort: ["-date_updated"],
  };
};

export const categoriesFetchQuery = {
  fields: ["id", "name", "icon", "slug"],
  filter: {
    type: { _eq: "category" },
  },
};

interface Products_Count {
  count: string;
}

export type RES_Products_Count = Products_Count[];

export const productsCountFetchQuery = () => {
  return {
    filter: {
      status: { _eq: "published" },
    },
    aggregate: { count: "*" },
  };
};

export const categoryCountFetchQuery = (name: string) => {
  return {
    filter: {
      tags: { tag_id: { name: { _eq: name } } },
    },
    aggregate: { count: "*" },
  };
};

export interface ClientData {
  id: string;
  logo: string;
  name: string;
  website_link?: string | null;
}
export type RES_Clients = ClientData[];
export const clientFetchQuery = (limit?: number) => {
  return {
    filter: {
      status: { _eq: "published" },
    },
    fields: ["id", "logo", "name", "website_link"],
    sort: ["-date_updated"],
    limit: limit || -1,
  };
};

export interface ProjectHighlight {
  id: string;
  cover_image: string | null;
  title: string;
  description: string;
  date_updated: string;
  highlight_type: "commercial" | "residential";
}

export type RES_ProjectHighlights = ProjectHighlight[];

export const projectHighlightsPerPageLimit = 6;

export const projectHighlightsLatestFetchQuery = (
  queryTake: "recent" | "all"
) => {
  if (queryTake === "recent") {
    return {
      filter: {
        status: { _eq: "published" },
        type: { _eq: "project" },
      },
      fields: articleFieldsNoContent,
      limit: 3,
      sort: ["-date_updated"],
    };
  } else
    return {
      filter: {
        status: { _eq: "published" },
        type: { _eq: "project" },
      },
      fields: articleFieldsNoContent,
      limit: -1,
      sort: ["-date_updated"],
    };
};

interface ProjectHighlights_Count {
  count: string;
}

export type RES_ProjectHighlights_Count = ProjectHighlights_Count[];

export const projectHighlightsCountFetchQuery = (
  highlightType?: "commercial" | "residential"
) => {
  if (highlightType) {
    return {
      filter: {
        status: { _eq: "published" },
        type: { _eq: "project" },
        highlight_type: { _eq: highlightType },
      },
      aggregate: { count: "*" },
    };
  }
  return {
    filter: {
      status: { _eq: "published" },
      type: { _eq: "project" },
    },
    aggregate: { count: "*" },
  };
};

export const projectHighlightsPaginatedFetchQuery = (
  page: number,
  limit: number,
  highlightType?: "commercial" | "residential"
) => {
  if (highlightType) {
    return {
      filter: {
        status: { _eq: "published" },
        type: { _eq: "project" },
        highlight_type: { _eq: highlightType },
      },
      fields: articleFieldsNoContent,
      limit: limit,
      page: page,
      sort: ["-date_updated"],
    };
  }
  return {
    filter: {
      status: { _eq: "published" },
      type: { _eq: "project" },
    },
    fields: articleFieldsNoContent,
    limit: limit,
    page: page,
    sort: ["-date_updated"],
  };
};

export const solutionsFetchQuery = (queryTake: "recent" | "all") => {
  if (queryTake === "recent") {
    return {
      filter: {
        status: { _eq: "published" },
        type: { _eq: "solution" },
      },
      fields: articleFieldsNoContent,
      sort: ["-date_updated"],
      limit: 3,
    };
  } else
    return {
      filter: {
        status: { _eq: "published" },
        type: { _eq: "solution" },
      },
      fields: articleFieldsNoContent,
      sort: ["-date_updated"],
      limit: -1,
    };
};

export type RES_Solutions = Solution[];

export interface Solution {
  id: string;
  cover_image: string | null;
  title: string;
  description: string | null;
  date_updated: string;
}

export const servicesFetchQuery = (queryTake: "all" | "recent") => {
  if (queryTake === "recent") {
    return {
      filter: {
        status: { _eq: "published" },
        type: { _eq: "service" },
      },
      fields: articleFieldsNoContent,
      sort: ["-date_updated"],
      limit: 3,
    };
  } else
    return {
      filter: {
        status: { _eq: "published" },
        type: { _eq: "service" },
      },
      fields: articleFieldsNoContent,
      sort: ["-date_updated"],
      limit: -1,
    };
};
export type RES_Services = Service[];

export interface Service {
  id: string;
  title: string;
  description: string | null;
  icon: string | null;
}

export const projectHighlightSingleFetchQuery = () => {
  return {
    filter: {
      status: { _eq: "published" },
      type: { _eq: "project" },
    },
    fields: articleFields,
  };
};

export const solutionSingleFetchQuery = () => {
  return {
    filter: {
      status: { _eq: "published" },
      type: { _eq: "solution" },
    },
    fields: articleFields,
  };
};

const articleFields = [
  "id",
  "title",
  "cover_image",
  "description",
  "content",
  "icon",
  "article_date",
  "date_updated",
  "highlight_type",
];

const articleFieldsNoContent = [
  "id",
  "title",
  "cover_image",
  "description",
  "icon",
  "article_date",
  "date_updated",
  "highlight_type",
];

export type RES_ArticlesData = RES_ArticleSingleData[];

export interface RES_ArticleSingleData {
  id: string;
  title: string;
  cover_image: string | null;
  description: string | null;
  content: string | null;
  icon: string | null;
  article_date: string | null;
}

export interface Fetch404Error {
  error: {
    statusCode: number | undefined;
  };
}
