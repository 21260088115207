<template>
  <footer class="h-auto">
    <div class="flex flex-col h-full justify-between">
      <hr />
      <div class="grid grid-cols-2 md:grid-cols-5 my-4 gap-4 px-5 md:p-4">
        <div
          class="hidden md:flex flex-col gap-2 col-span-2 md:col-span-1 mb-2 md:mb-0"
        >
          <div class="w-full flex flex-col items-center gap-2">
            <img
              class="object-contain w-24 dark:opacity-75"
              width="96"
              height="96"
              src="/logo-only.svg"
              alt="TechGen Solutions Logo"
            />
            <img
              class="object-contain w-64 dark:opacity-75"
              width="256"
              height="40"
              src="/logo-motto-only.svg"
              alt="Technology Generates Solutions"
            />
          </div>
        </div>
        <div class="hidden md:block"></div>

        <nav class="nav-parent">
          <NuxtLink to="/solutions" :external="err">
            <div class="section-header flex items-center gap-2">Solutions</div>
          </NuxtLink>
          <div class="section-text">
            <LayoutFooterSolutionsList
              :siteErr="!!err"
            ></LayoutFooterSolutionsList>
          </div>
          <div class="section-text">
            <NuxtLink :external="err" to="/solutions#services"
              ><div class="flex items-center gap-2">Services</div></NuxtLink
            >
          </div>
        </nav>

        <nav class="nav-parent">
          <NuxtLink :external="err" to="/projects">
            <div class="section-header flex items-center gap-2">Projects</div>
          </NuxtLink>
          <div class="section-text">
            <NuxtLink :external="err" to="/projects/highlights">
              <div class="flex items-center gap-2">Highlights</div>
            </NuxtLink>
            <NuxtLink :external="err" to="/projects#our-clients">
              <div class="flex items-center gap-2">Our Clients</div>
            </NuxtLink>
          </div>
        </nav>
        <nav class="nav-parent">
          <NuxtLink :external="err" to="/company">
            <div class="section-header flex items-center gap-2 flex-nowrap">
              Company
            </div>
          </NuxtLink>
          <div class="section-text">
            <NuxtLink :external="err" to="/company#about"
              ><div class="flex items-center gap-2">About</div></NuxtLink
            >
            <NuxtLink :external="err" to="/company#contact-us"
              ><div class="flex items-center gap-2">Contact Us</div></NuxtLink
            >
          </div>
        </nav>
      </div>
      <div
        class="flex flex-col justify-center w-full pb-3 text-sm text-slate-500"
      >
        <div class="text-center">
          © 2021 - {{ currentYear }} All Rights Reserved
        </div>
        <div class="text-center">
          TechGen Solutions Private Limited (UEN: 202100781G)
        </div>
        <div class="text-center text-xs">
          All logos are the property of their respective owners
        </div>
        <div class="text-center text-xs">
          <NuxtLink to="/privacy-policy"> Privacy Policy</NuxtLink>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
defineProps<{ err?: boolean }>();

const currentYear = new Date().getFullYear();
</script>

<style scoped>
.nav-parent {
  @apply flex flex-col gap-3 max-w-fit;
}

.nav-parent div,
.nav-parent a {
  @apply w-auto max-w-fit;
}

.section-header {
  @apply tracking-wider text-lg font-bold dark:text-blue-500 uppercase;
}

.section-text {
  @apply dark:text-slate-400 md:leading-7;
}

.section-text a div {
  @apply mb-1 md:mb-0;
}
</style>
