<template>
  <NuxtLink v-for="link of data" :key="link.id" :to="getLinkUrl(link)">
    <div class="mb-1 md:mb-0">{{ link.title }}</div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import {
  solutionsFetchQuery,
  type RES_Solutions,
  type Solution,
} from "~/fetchConstants";

defineProps<{ siteErr: boolean }>();

const { data } = await useFetch<RES_Solutions>(`/api/read/article`, {
  query: solutionsFetchQuery("all"),
});

const getLinkUrl = (item: Solution) => {
  const slug = useGenSlug(item.title);
  return `/solutions/${item.id}/${slug}`;
};
</script>
