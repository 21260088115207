const PRODUCTS_DESCRIPTION =
  "Discover our range of IT products available for purchase, including hardware, software, and security solutions from trusted brands.";
const PRODUCTS_TITLE = "Products";

const SOLUTIONS_DESCRIPTION =
  "Discover our comprehensive IT services designed to optimize your business, from Managed IT and Infrastructure Planning to custom solutions tailored to your needs.";
const SOLUTIONS_TITLE = "Solutions";

const COMPANY_TITLE = "Company";
const COMPANY_DESCRIPTION =
  "Learn more about our company, our values, and our commitment to providing the best IT solutions and services.";

const HOMEPAGE_TITLE = "TechGen Solutions";
const HOMEPAGE_DESCRIPTION =
  "Technology Generates Solutions - Trusted & Reliable IT Solutions Provider";

const PROJECTS_TITLE = "Projects";
const PROJECTS_DESCRIPTION =
  "Explore our recent projects and see how TechGen Solutions has helped clients optimize their businesses with Managed IT, Infrastructure Planning, and custom IT solutions.";

const PROJECTS_HIGHLIGHTS_TITLE = "Project Highlights";
const PROJECTS_HIGHLIGHTS_DESCRIPTION =
  "Check out our project highlights to see how TechGen Solutions has delivered exceptional IT services, including Managed IT, Infrastructure Planning, and custom solutions, helping businesses achieve their goals.";

export const seoConstants = {
  products: {
    description: PRODUCTS_DESCRIPTION,
    title: PRODUCTS_TITLE,
    searchTitle: (search?: string, category?: string) => {
      if (search && category) {
        return `Search ${search} in ${category}`;
      }
      if (search && !category) {
        return `Search ${search}`;
      }
      if (!search && category) {
        return `Search ${category}`;
      }
      return PRODUCTS_TITLE;
    },
    searchDescription: (search?: string, category?: string) => {
      if (search && category) {
        return `Product search results for "${search}" in "${category}".`;
      }
      if (search && !category) {
        return `Product search results for "${search}".`;
      }
      if (!search && category) {
        return `Product search results for "${category}".`;
      }
      return PRODUCTS_DESCRIPTION;
    },
    single: {
      title: (name: string, partNumStr?: string) => {
        if (partNumStr) {
          return `Product: ${name} - ${partNumStr}`;
        }
        return `Product: ${name}`;
      },
      description: (name: string, partNumStr?: string) => {
        if (partNumStr) {
          return `${name} - ${partNumStr} - Product details and specifications`;
        }
        return `${name} - Product details and specifications`;
      },
    },
  },
  solutions: {
    title: SOLUTIONS_TITLE,
    description: SOLUTIONS_DESCRIPTION,
  },
  company: {
    title: COMPANY_TITLE,
    description: COMPANY_DESCRIPTION,
  },
  homepage: {
    title: HOMEPAGE_TITLE,
    description: HOMEPAGE_DESCRIPTION,
  },
  projects: {
    title: PROJECTS_TITLE,
    description: PROJECTS_DESCRIPTION,
    highlights: {
      title: PROJECTS_HIGHLIGHTS_TITLE,
      description: PROJECTS_HIGHLIGHTS_DESCRIPTION,
    },
    single: {
      title: (name: string) => `Project: ${name}`,
      description: (name: string) =>
        `Project: ${name} - Details and highlights`,
    },
  },
};
