<template>
  <div class="flex justify-center items-center h-full min-h-96" v-if="error">
    <div class="flex flex-col items-center">
      <h1 class="text-4xl font-bold text-orange-400 animate-pulse">
        Error - {{ error.statusCode }}
      </h1>
      <p class="text-slate-500">Error message</p>
      <p class="text-xl">{{ error.statusMessage }}</p>
      <p class="text-slate-500">Error URL</p>
      <p class="text-xl">{{ getUrl() }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from "#app";
const route = useRoute();

const props = defineProps<{ error?: NuxtError }>();

const getUrl = () => {
  const err = props.error as any;
  if (err?.url) {
    return err.url;
  } else {
    return route.fullPath;
  }
};
</script>
