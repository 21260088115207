<template>
  <header class="flex flex-row">
    <div class="w-[200px] md:w-[400px] h-[50px] ml-2 md:ml-0">
      <NuxtLink
        class="max-w-fit"
        :external="!!err"
        to="/"
        aria-label="TechGen Solutions Home page"
      >
        <div class="w-fit h-full flex gap-2 items-center">
          <NuxtImg
            class="h-[44px] object-contain"
            width="44"
            height="44"
            src="/logo-only.svg"
            alt="TechGen Solutions Logo"
            sizes="44px"
          /><NuxtImg
            class="h-[44px] md:h-[36px] object-contain dark:opacity-90"
            fit="inside"
            width="200"
            height="44"
            src="/logo-motto-only.svg"
            alt="Technology Genereates Solutions"
            sizes="200px"
          />
        </div>
      </NuxtLink>
    </div>
    <!-- DESKTOP LINKS -->
    <nav
      class="ml-3 hidden md:flex flex-row gap-2 items-center font-semibold tracking-widest text-blue-500 text-lg w-full"
    >
      <div
        class="flex-auto flex flex-row justify-center gap-10 items-center font-sans"
      >
        <NuxtLink :external="!!err" to="/solutions" class="nav-link"
          ><div>SOLUTIONS</div></NuxtLink
        >
        <NuxtLink :external="!!err" to="/projects" class="nav-link"
          ><div>PROJECTS</div></NuxtLink
        >
        <NuxtLink :external="!!err" to="/products" class="nav-link"
          ><div>PRODUCTS</div></NuxtLink
        >
        <NuxtLink :external="!!err" to="/company" class="nav-link"
          ><div>COMPANY</div></NuxtLink
        >
      </div>

      <div class="flex-auto flex justify-center">
        <div class="mr-2">
          <Button
            class="bg-blue-700 dark:bg-blue-800 dark:text-slate-200 dark:border-0 hover:bg-blue-700 dark:hover:bg-blue-900"
            size="small"
            @click.prevent="handleBtnContact"
            >Contact Us</Button
          >
        </div>
      </div>
    </nav>
    <!-- MOBILE MENU -->
    <nav
      class="flex-grow flex flex-row justify-end items-center md:hidden text-black dark:text-white"
    >
      <div class="mr-4">
        <div @click.prevent="showMobileMenu = !showMobileMenu">
          <Icon
            name="ant-design:close-outlined"
            class="text-3xl text-slate-500 dark:text-slate-400"
            v-if="showMobileMenu"
          ></Icon>
        </div>
        <div @click.prevent="showMobileMenu = !showMobileMenu">
          <Icon
            name="ant-design:menu-outlined"
            class="text-3xl text-slate-500 dark:text-slate-400"
            v-if="!showMobileMenu"
          ></Icon>
        </div>
      </div>
    </nav>
    <Drawer v-model:visible="showMobileMenu" :show-close-icon="false">
      <div class="max-h-fit mb-3">
        <NuxtLink
          :external="!!err"
          to="/"
          aria-label="TechGen Solutions Home page"
          @click="showMobileMenu = !showMobileMenu"
        >
          <div class="flex flex-col gap-2 items-center">
            <img
              class="object-contain h-14"
              src="/logo-only.svg"
              alt="TechGen Solutions Logo"
            /><img
              class="object-contain h-8 dark:opacity-75"
              src="/logo-motto-only.svg"
              alt="Technology Generates Solutions"
            />
          </div>
        </NuxtLink>
      </div>
      <hr />
      <nav class="text-3xl tracking-wider flex flex-col gap-2 uppercase">
        <NuxtLink
          :external="!!err"
          to="/solutions"
          class="nav-link text-2xl"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">Solutions</div></NuxtLink
        >
        <NuxtLink
          :external="!!err"
          to="/solutions#services"
          class="nav-link ml-4 text-xl my-2"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">Services</div></NuxtLink
        >
        <NuxtLink
          :external="!!err"
          to="/projects"
          class="nav-link text-2xl"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">Projects</div></NuxtLink
        >
        <NuxtLink
          :external="!!err"
          to="/projects/highlights"
          class="nav-link ml-4 text-xl mt-2"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">Highlights</div></NuxtLink
        >
        <NuxtLink
          :external="!!err"
          to="/projects#our-clients"
          class="nav-link ml-4 text-xl mt-2"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">Our Clients</div></NuxtLink
        >
        <NuxtLink
          :external="!!err"
          to="/products"
          class="nav-link my-2 text-2xl"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">Products</div></NuxtLink
        >
        <NuxtLink
          :external="!!err"
          to="/company"
          class="nav-link text-2xl"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">Company</div></NuxtLink
        >
        <NuxtLink
          :external="!!err"
          to="/company"
          class="nav-link ml-4 mt-2 text-xl"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">About</div></NuxtLink
        >
        <NuxtLink
          :external="!!err"
          to="/company#contact-us"
          class="nav-link ml-4 mt-2 text-xl"
          @click="showMobileMenu = !showMobileMenu"
          ><div class="flex items-center gap-2">Contact Us</div></NuxtLink
        >
      </nav>
    </Drawer>
  </header>
</template>

<script lang="ts" setup>
defineProps<{ err?: boolean }>();

const showMobileMenu = ref(false);

const handleBtnContact = async () => {
  await navigateTo("/company#contact-us");
};
</script>

<style scoped>
.nav-link {
  @apply transition-all duration-300 text-blue-600 dark:text-blue-400 no-underline font-normal md:font-medium;
}

.nav-link:not(.router-link-active):not(.router-link-exact-active):hover {
  @apply transition-all duration-300 text-blue-600 dark:text-blue-400 underline underline-offset-4 decoration-2 decoration-orange-300;
}

/* .router-link-active,
.router-link-exact-active {
  @apply transition-all duration-300 text-blue-700 dark:text-blue-400 underline underline-offset-4 decoration-2 decoration-orange-300 font-medium;
} */

.router-link-exact-active {
  @apply transition-all duration-300 text-blue-700 dark:text-blue-400 underline underline-offset-4 decoration-2 decoration-orange-300 font-medium;
}
</style>
